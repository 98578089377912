<template>
  <div
    id="calculoid"
    ng-controller="CalculoidMainCtrl"
    :ng-init="
      `init({calcId:${calculoidId},apiKey:'${apiKey}',showTitle:0,showDescription:0,values:{ ` +
        `${aliases.CONTRACT_ALIAS} : '${data.contractId}',` +
        `${aliases.EMAIL_ALIAS} : '${data.email}',` +
        `${aliases.QUOTATION_ALIAS} : '${orderId}',` +
        `${aliases.PRODUCT_NAME_ALIAS} : '${data.productName}',` +
        `${aliases.PRODUCT_DESCRIPTION_ALIAS} : '${data.productDescription}',` +
        `${aliases.TOTAL_PAYMENT_ALIAS} : '${data.singleTotalPayment}',` +
        `${aliases.CURRENCY_ALIAS} : '${data.currency}',` +
        `${aliases.RECURRENT_ACTIVE_ALIAS} : '${recurrentPaymentActive}',` +
        `${aliases.RECURRENT_CYCLE_ALIAS} : '${data.recurrentPaymentInfo.cycle}',` +
        `${aliases.RECURRENT_PERIOD_ALIAS} : '${data.recurrentPaymentInfo.period}',` +
        `${aliases.RECURRENT_TOTAL_PAYMENT_FIRST_ALIAS} : '${data.recurrentPaymentInfo.totalPaymentFirst}',` +
        `${aliases.RECURRENT_TOTAL_PAYMENT_ALIAS} : '${data.recurrentPaymentInfo.totalPayment}'}})`
    "
    ng-include="load()"
    data-cy="calculoid"
  />
</template>

<script>
import { aliases, auth } from '../../../constants/calculoid'
export default {
  props: {
    orderId: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      aliases: aliases,
      apiKey: auth.API_KEY
    }
  },
  computed: {
    recurrentPaymentActive () {
      return this.data.recurrentPaymentActive ? 1 : 0
    },
    calculoidId () {
      return this.data.calculoidId || auth.CALCULOID_ID
    }
  },
  mounted () {
    const calc = document.getElementById('calculoid')
    // eslint-disable-next-line no-undef
    angular.bootstrap(calc, ['calculoid'])
  }
}
</script>
