const CONTRACT_ALIAS = 'contract_id'
const EMAIL_ALIAS = 'billing_email'
const QUOTATION_ALIAS = 'quotation_id'
const PRODUCT_NAME_ALIAS = 'product_name'
const PRODUCT_DESCRIPTION_ALIAS = 'product_description'
const TOTAL_PAYMENT_ALIAS = 'total_payment'
const CURRENCY_ALIAS = 'currency'
const RECURRENT_ACTIVE_ALIAS = 'recurrent_active'
const RECURRENT_CYCLE_ALIAS = 'recurrent_cycle'
const RECURRENT_PERIOD_ALIAS = 'recurrent_period'
const RECURRENT_TOTAL_PAYMENT_FIRST_ALIAS = 'recurrent_total_payment_first'
const RECURRENT_TOTAL_PAYMENT_ALIAS = 'recurrent_total_payment'

const API_KEY = process.env.VUE_APP_CALCULOID_API_KEY
const CALCULOID_ID = process.env.VUE_APP_CALCULOID_ID

module.exports = {
  aliases: {
    CONTRACT_ALIAS,
    EMAIL_ALIAS,
    QUOTATION_ALIAS,
    PRODUCT_NAME_ALIAS,
    PRODUCT_DESCRIPTION_ALIAS,
    TOTAL_PAYMENT_ALIAS,
    CURRENCY_ALIAS,
    RECURRENT_ACTIVE_ALIAS,
    RECURRENT_CYCLE_ALIAS,
    RECURRENT_PERIOD_ALIAS,
    RECURRENT_TOTAL_PAYMENT_FIRST_ALIAS,
    RECURRENT_TOTAL_PAYMENT_ALIAS
  },
  auth: {
    API_KEY,
    CALCULOID_ID
  }
}
