var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"calculoid","ng-controller":"CalculoidMainCtrl","ng-init":"init({calcId:" + _vm.calculoidId + ",apiKey:'" + _vm.apiKey + "',showTitle:0,showDescription:0,values:{ " +
      (_vm.aliases.CONTRACT_ALIAS) + " : '" + (_vm.data.contractId) + "'," +
      (_vm.aliases.EMAIL_ALIAS) + " : '" + (_vm.data.email) + "'," +
      (_vm.aliases.QUOTATION_ALIAS) + " : '" + _vm.orderId + "'," +
      (_vm.aliases.PRODUCT_NAME_ALIAS) + " : '" + (_vm.data.productName) + "'," +
      (_vm.aliases.PRODUCT_DESCRIPTION_ALIAS) + " : '" + (_vm.data.productDescription) + "'," +
      (_vm.aliases.TOTAL_PAYMENT_ALIAS) + " : '" + (_vm.data.singleTotalPayment) + "'," +
      (_vm.aliases.CURRENCY_ALIAS) + " : '" + (_vm.data.currency) + "'," +
      (_vm.aliases.RECURRENT_ACTIVE_ALIAS) + " : '" + _vm.recurrentPaymentActive + "'," +
      (_vm.aliases.RECURRENT_CYCLE_ALIAS) + " : '" + (_vm.data.recurrentPaymentInfo.cycle) + "'," +
      (_vm.aliases.RECURRENT_PERIOD_ALIAS) + " : '" + (_vm.data.recurrentPaymentInfo.period) + "'," +
      (_vm.aliases.RECURRENT_TOTAL_PAYMENT_FIRST_ALIAS) + " : '" + (_vm.data.recurrentPaymentInfo.totalPaymentFirst) + "'," +
      (_vm.aliases.RECURRENT_TOTAL_PAYMENT_ALIAS) + " : '" + (_vm.data.recurrentPaymentInfo.totalPayment) + "'}})","ng-include":"load()"}})}
var staticRenderFns = []

export { render, staticRenderFns }